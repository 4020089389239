.main-sev-area {
    display: flex;
    width: 100%;
    height: 100%;
}

.sidebar {
    width: 25%;
    background-color: #fff;
    padding: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.search-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.select-contact,
.search-box input {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.active-chats {
    flex: 1;
}

.chat-list {
    list-style: none;
    padding: 0;
}

.chat-item {
    /* padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer; */
}

.chat-item .chat-name {
    font-weight: bold;
}

.chat-item .chat-date {
    float: right;
    font-size: 0.8em;
    color: #888;
}

.chat-area {
    flex: 2;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ddd;
}

.chat-contact {
    display: flex;
    flex-direction: column;
}

.chat-messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
}

.message {
    margin-bottom: 10px;
    background-color: white !important;

}

.message.received p {
    background-color: #e1f0ff;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
}

.message.sent p {
    background-color: #d1ffd7;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
}

.message-time {
    display: block;
    font-size: 0.8em;
    color: #888;
    margin-top: 5px;
}

.chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.chat-input input {
    flex: 1;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-right: 10px;
}

.send-button {
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.info-sidebar {
    width: 20%;
    background-color: #fff;
    padding: 10px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.contact-info {
    margin-bottom: 20px;
}

.contact-options {
    flex: 1;
}

.option {
    padding: 10px;
    background-color: #f1f1f1;
    margin-bottom: 10px;
    cursor: pointer;
}

.block-button {
    background-color: #ff0000;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.tagName {
    height: 3rem;
    width: 3rem;
    margin-right: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background: #d4dbf9;
    font-weight: 700;
    border-radius: 100%;
    color: #fff;
}

.right-show {
    display: flex;
    justify-content: space-between;
    overflow: hidden !important;
    align-items: center;
    width: 80%;
}

.chat-item {
    display: flex;
    align-items: center;
}