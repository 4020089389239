.tab-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    justify-content: space-around;
  }
  
  .tab-buttons button {
    padding: 10px 20px;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .tab-buttons button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
 /*  .tab-content {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  } */
  
  .reoveExtr input{
    border-radius: 4px;
  }
 .card .whatsaapMobile{
    border-radius:0;
   width: 100%;
   height: 80%;
  }