.form-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* max-width: 500px; */
    flex: 1;
    /* margin: auto; */
  }
  
  .form-container h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 4px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group label span {
    color: red;
  }
  
  .form-control {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .btn-next {
    background-color: #3b82f6;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin-top: 20px;
  }
  
  .btn-next:hover {
    background-color: #2563eb;
  }
  
  .back-link {
    display: block;
    margin-top: 15px;
    color: #3b82f6;
    text-align: right;
    text-decoration: none;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }
  