.no-overlay .offcanvas-backdrop {
    background-color: rgba(0, 0, 0, 0);
    /* Removes transparency, making the overlay transparent */
}
.offcanvas-backdrop .show{
    opacity: 0 !important;
}

.offcanvas-header{
    background-color:rgb(44 44 44);
    padding: 1.2em;
}.no-overlay .offcanvas-header .offcanvas-title{
    color: #fff !important;
}
.no-overlay .offcanvas-header .btn-close {
    background-image: url("../../../../../../assets//images/graph/cross.png");
}



