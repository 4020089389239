.wrap-filter {
    justify-content: space-between;
}

.wrap-summery {
    background: #fff;
    box-shadow: 0px 0px 3px 1px #6e6e6e;
    border-radius: 10px;
}

.wrap-summery .title {
    font-size: 20px !important;
    vertical-align: baseline;
    font-weight: 500;
    color: #475569;
    padding: 15px;
    padding-bottom: 0px;
}

.sepretor {
    margin-top: 20x;
    gap: 15px;
    padding: 15px;
    padding-top: 0px;
    border-bottom: 2px solid #e2e8f0;
}

.sepretor li {
    border-right: 1px solid black;
    padding-right: 50px;
}

.sepretor li:last-child {
    border: none;
}

.sepretor li a {
    font-size: 16px;
}

.sepretor li a span {
    font-size: 18px;
    margin-right: 8px;
    color: black !important;
    font-weight: 500;
}

.primary {
    color: blue;
}

.danger {
    color: red;
}

.success {
    color: green;
}

.info {
    color: skyblue;
}

.main-tab {
    width: 100%;
}

.main-tr {
    padding: 20px;
    background-color: #F8FAFC;
}

.main-th {
    padding: 10px;
    border-color: rgba(226, 232, 240, .7);
    border: 1px rgba(226, 232, 240, .7);
    border-style: solid;
    color: #334155;
    font-size: 13.5px;
    font-weight: 500;

}

.sec-td {
    padding: 10px 0px 25px 0px;
    font-size: 14px;
    border-color: #EBEFF4;
    border-bottom: 1px #EBEFF4;
    border-style: solid;
    padding: 10px;
}

.border-b {
    border-bottom: 2px solid #EBEFF4;
}

.limit {
    width: 80px;
    border-color: #EBEFF4;
}

.limit:focus {
    border-color: blue;
}

.exp-btn {
    border: 1px solid #EBEFF4;
    border-radius: 8px;
    overflow: hidden;
}

.exp-btn button {
    border: none;
    background: white;
    padding: 3px 0px;
}

.exp-btn button:first-child {
    border-right: 1px solid #EBEFF4;
}

.exp-btn button:hover {
    background: rgb(241, 250, 253);
}

.search {
    width: 100px;
    border: none;
    padding: 3px;
}

.p-6 {
    padding: 30px;
}

.num {
    width: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    background: blue;
}

.border {
    border: 1px solid #EBEFF4;
    padding: 3px;
    border-radius: 5px;
}

.wrap-newProject {
    max-width: 700px;
    margin: 40px auto;
}
/* .active{
    color: blue;
    border-bottom: 1px solid blue;
} */